define("labs-zap-search/templates/components/date-range-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yWBAdVh7",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"range-slider\",null,[[\"start\",\"min\",\"max\",\"connect\",\"tooltips\",\"step\",\"behaviour\",\"on-change\"],[[24,[\"start\"]],[24,[\"min\"]],[24,[\"max\"]],true,[28,\"array\",[[24,[\"format\"]],[24,[\"format\"]]],null],1,\"drag\",\"sliderChanged\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/date-range-filter.hbs"
    }
  });

  _exports.default = _default;
});