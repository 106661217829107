define("labs-zap-search/templates/my-projects/assignment/recommendations/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Roj9hyTH",
    "block": "{\"symbols\":[\"project\"],\"statements\":[[4,\"with\",[[23,0,[\"model\"]]],null,{\"statements\":[[0,\"  \"],[5,\"link-to\",[],[[\"@route\"],[\"my-projects.to-review\"]],{\"statements\":[[0,\"\\n    < Back to \\\"To Review\\\"\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"br\",true],[8],[9],[0,\"\\n  \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"show-project\",[23,1,[]]]],{\"statements\":[[0,\"\\n    < Back to Project\\n  \"]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"cell\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"grid-container\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"grid-x grid-padding-x grid-padding-y align-middle\"],[10,\"style\",\"min-height: 50vh\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"cell large-6 large-offset-3 text-center\"],[8],[0,\"\\n          \"],[7,\"h2\",true],[8],[0,\"Thank you\"],[9],[0,\"\\n          \"],[7,\"p\",true],[8],[0,\"Your recommendation was submitted.\"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/my-projects/assignment/recommendations/view.hbs"
    }
  });

  _exports.default = _default;
});