define("labs-zap-search/models/project/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DCP_APPLICABILITY_OPTIONSET = _exports.DCPPUBLICSTATUS_OPTIONSET = void 0;
  var DCPPUBLICSTATUS_OPTIONSET = {
    // eslint-disable-line
    NOTICED: 717170005,
    FILED: 717170000,
    REVIEW: 717170001,
    COMPLETED: 717170002
  };
  _exports.DCPPUBLICSTATUS_OPTIONSET = DCPPUBLICSTATUS_OPTIONSET;
  var DCP_APPLICABILITY_OPTIONSET = {
    YES: {
      code: 1,
      label: 'Yes',
      altLabel: 'Racial Equity Report Required'
    },
    NO: {
      code: 2,
      label: 'No',
      altLabel: 'Racial Equity Report Not Required'
    },
    UNSURE: {
      code: null,
      label: 'Unsure at this time',
      altLabel: 'Racial Equity Report Requirements Unknown'
    }
  };
  _exports.DCP_APPLICABILITY_OPTIONSET = DCP_APPLICABILITY_OPTIONSET;
});