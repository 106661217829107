define("labs-zap-search/templates/components/sign-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LppzEFKn",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\",true],[8],[4,\"link-to\",null,[[\"route\"],[\"my-projects\"]],{\"statements\":[[7,\"span\",true],[8],[0,\"My Projects\"],[9]],\"parameters\":[]},null],[9],[0,\"\\n  \"],[7,\"li\",true],[10,\"class\",\"auth--container\"],[8],[0,\"\\n    \"],[1,[28,\"fa-icon\",null,[[\"icon\",\"size\",\"class\"],[\"user-circle\",\"2x\",\"auth--icon\"]]],false],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"auth--name\"],[8],[0,\"\\n        \"],[1,[23,0,[\"currentUser\",\"user\",\"emailaddress1\"]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"a\",true],[10,\"class\",\"auth--logout-button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[23,0,[\"logout\"]]],null]],[8],[0,\"\\n        Sign Out\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"li\",true],[8],[0,\"\\n    \"],[7,\"a\",false],[12,\"class\",\"auth--login-button\"],[3,\"action\",[[23,0,[]],[24,[\"toggleAuthModal\"]]]],[8],[0,\"\\n      \"],[1,[28,\"fa-icon\",null,[[\"icon\",\"class\"],[\"user-circle\",\"light-gray\"]]],false],[0,\"\\n      Sign In\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"ember-wormhole\",null,[[\"to\"],[\"reveal-modal-container\"]],{\"statements\":[[4,\"confirmation-modal\",null,[[\"open\"],[[24,[\"showAuthModal\"]]]],{\"statements\":[[0,\"    \"],[1,[22,\"auth/login-dialog\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/sign-in.hbs"
    }
  });

  _exports.default = _default;
});