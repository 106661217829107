define("labs-zap-search/templates/components/milestones/public-hearing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XIapp3J9",
    "block": "{\"symbols\":[\"action\",\"index\",\"@hearing\"],\"statements\":[[7,\"strong\",true],[8],[0,\"Public Hearing\"],[9],[0,\"\\n\\n\"],[7,\"small\",true],[10,\"class\",\"display-inline-block\"],[8],[0,\"\\n  \"],[1,[23,3,[\"disposition\",\"dcpPublichearinglocation\"]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"small\",true],[10,\"class\",\"display-inline-block\"],[8],[1,[28,\"moment-format\",[[23,3,[\"disposition\",\"dcpDateofpublichearing\"]],\"LLLL\"],null],false],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"text-tiny\"],[8],[0,\"\\n\"],[4,\"each\",[[23,3,[\"hearingActions\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"dcpName\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"wrap-content\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"label light-gray tiny-margin-bottom tiny-margin-right\"],[8],[0,\"\\n          \"],[1,[23,1,[\"dcpName\"]],false],[0,\"\\n          \"],[7,\"small\",true],[8],[1,[23,1,[\"dcpUlurpnumber\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/milestones/public-hearing.hbs"
    }
  });

  _exports.default = _default;
});