define("labs-zap-search/templates/components/deduped-hearings-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zqB9BRNN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[24,[\"dedupedHearings\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/deduped-hearings-list.hbs"
    }
  });

  _exports.default = _default;
});