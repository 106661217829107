define("labs-zap-search/templates/components/icon-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vM49tA9h",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"tool-tipster\",null,[[\"content\",\"tagName\",\"contentAsHTML\",\"interactive\"],[[24,[\"tip\"]],\"span\",true,true]],{\"statements\":[[1,[28,\"fa-icon\",[[24,[\"icon\"]]],null],false]],\"parameters\":[]},null],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/icon-tooltip.hbs"
    }
  });

  _exports.default = _default;
});