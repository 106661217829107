define("labs-zap-search/helpers/fs-set-user-vars", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fsSetUserVars = fsSetUserVars;
  _exports.default = void 0;
  var InvalidError = _emberData.default.InvalidError;

  function fsSetUserVars(uservars) {
    try {
      // eslint-disable-next-line no-undef
      FS.setUserVars(uservars);
    } catch (e) {
      throw new InvalidError([{
        detail: e,
        message: 'FS.setUserVars failed.'
      }]);
    }
  }

  var _default = Ember.Helper.helper(fsSetUserVars);

  _exports.default = _default;
});