define("labs-zap-search/templates/components/applied-filters-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L2jKfW4V",
    "block": "{\"symbols\":[\"name\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"no-results-message text-center dark-gray\"],[8],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"medium-gray\"],[8],[1,[28,\"fa-icon\",null,[[\"icon\",\"size\"],[\"search\",\"4x\"]]],false],[9],[0,\"\\n  \"],[7,\"h4\",true],[8],[0,\"No Results. Please adjust your filters:\"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"currentFiltersNames\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/applied-filters-list.hbs"
    }
  });

  _exports.default = _default;
});