define("labs-zap-search/templates/components/projects-map-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TKly6CRL",
    "block": "{\"symbols\":[\"map\",\"&default\"],\"statements\":[[4,\"mapbox-gl\",null,[[\"id\",\"initOptions\",\"mapLoaded\"],[\"project-map\",[28,\"hash\",null,[[\"zoom\",\"center\",\"maxzoom\"],[12,[28,\"array\",[-73.96532400540775,40.709710016659386],null],9]]],[28,\"action\",[[23,0,[]],\"handleMapLoad\"],null]]],{\"statements\":[[0,\"  \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"on\"]],\"expected `map.on` to be a contextual component but found a string. Did you mean `(component map.on)`? ('labs-zap-search/templates/components/projects-map-data.hbs' @ L11:C4) \"],null],\"click\",[28,\"action\",[[23,0,[]],\"handleMapClick\"],null]],null],false],[0,\"\\n  \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"on\"]],\"expected `map.on` to be a contextual component but found a string. Did you mean `(component map.on)`? ('labs-zap-search/templates/components/projects-map-data.hbs' @ L12:C4) \"],null],\"mousemove\",[28,\"action\",[[23,0,[]],\"handleMouseMove\"],null]],null],false],[0,\"\\n\\n\\n\"],[4,\"if\",[[24,[\"tilesLoading\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"fa-icon\",null,[[\"icon\",\"spin\",\"size\",\"class\"],[\"spinner\",\"true\",\"5x\",\"map-loading-spinner\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[14,2,[[28,\"merge\",[[23,1,[]],[28,\"hash\",null,[[\"dynamic-tiles\"],[[28,\"component\",[\"mapbox-gl-dynamic-tiles\"],[[\"map\",\"mapInstance\"],[[23,1,[]],[24,[\"mapInstance\"]]]]]]]]],null]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/projects-map-data.hbs"
    }
  });

  _exports.default = _default;
});