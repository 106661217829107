define("labs-zap-search/templates/components/filters/named-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q/ZXtLsJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"fa-icon\",[[28,\"if\",[[24,[\"mainProperty\"]],\"check-square\",\"square\"],null]],[[\"prefix\",\"class\"],[[28,\"unless\",[[24,[\"mainProperty\"]],\"far\"],null],[28,\"if\",[[24,[\"mainProperty\"]],\"dark-gray\",\"light-gray\"],null]]]],false],[0,\" \"],[1,[22,\"label\"],false],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/filters/named-checkbox.hbs"
    }
  });

  _exports.default = _default;
});