define("labs-zap-search/templates/components/document-upload-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "poC8chP0",
    "block": "{\"symbols\":[\"file\"],\"statements\":[[7,\"fieldset\",true],[10,\"class\",\"medium-margin-bottom\"],[8],[0,\"\\n  \"],[7,\"legend\",true],[8],[0,\"\\n    \"],[7,\"strong\",true],[8],[0,\"\\n      Upload supporting documents\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-x grid-margin-x\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"cell medium-shrink\"],[8],[0,\"\\n      \"],[5,\"file-upload\",[[12,\"id\",[28,\"concat\",[[23,0,[\"queue\",\"name\"]],\"FileUpload\"],null]],[12,\"class\",\"button gray no-margin\"]],[[\"@name\",\"@accept\",\"@multiple\",\"@onfileadd\"],[[23,0,[\"queue\",\"name\"]],\"*/*\",true,[23,0,[\"onFileAdd\"]]]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"fa-icon\",null,[[\"icon\"],[\"upload\"]]],false],[0,\" Choose Files\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"cell auto\"],[8],[0,\"\\n      \"],[7,\"ul\",true],[10,\"class\",\"no-bullet\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"queue\",\"files\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\",true],[8],[0,\"\\n            \"],[7,\"a\",false],[3,\"action\",[[23,0,[]],\"removeFile\",[23,0,[\"queue\"]],[23,1,[]]]],[8],[0,\"\\n              \"],[1,[28,\"fa-icon\",null,[[\"icon\",\"class\",\"fixedWidth\"],[\"times\",\"red-muted\",true]]],false],[0,\"\\n            \"],[9],[0,\"\\n            \"],[1,[23,1,[\"name\"]],false],[0,\"\\n            \"],[7,\"small\",true],[8],[0,\"\\n              (\"],[1,[23,1,[\"type\"]],false],[0,\")\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/document-upload-form.hbs"
    }
  });

  _exports.default = _default;
});