define("labs-zap-search/router", ["exports", "ember-router-scroll", "labs-zap-search/config/environment"], function (_exports, _emberRouterScroll, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend(_emberRouterScroll.default, {
    metrics: Ember.inject.service(),
    didTransition: function didTransition() {
      this._super.apply(this, arguments);

      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var page = _this.url;

        var title = _this.getWithDefault('currentRouteName', 'unknown');

        _this.metrics.trackPage({
          page: page,
          title: title
        });
      });
    },
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    // eslint-disable-line
    this.route('show-project', {
      path: '/projects/:id'
    });
    this.route('show-geography', {
      path: '/projects'
    });
    this.route('disclaimer');

    if (_environment.default.showAlerts) {
      this.route('statuses');
      this.route('subscribed');
      this.route('subscription-confirmation', {
        path: '/subscribers/:id/confirm'
      });
      this.route('subscribe');
    }

    this.route('not-found', {
      path: '/*path'
    });
    this.route('oops');
    this.route('my-projects', function () {
      this.route('to-review');
      this.route('archive');
      this.route('upcoming');
      this.route('reviewed');
      this.route('assignment', {
        path: ':assignment_id'
      }, function () {
        this.route('hearing', function () {
          this.route('add');
          this.route('done');
        });
        this.route('recommendations', function () {
          this.route('add');
          this.route('view');
          this.route('done');
        });
      });
    });
    this.route('login');
    this.route('logout');
  });
  var _default = Router;
  _exports.default = _default;
});