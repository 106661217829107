define("labs-zap-search/validations/recommendation", ["exports", "ember-changeset-validations/validators", "labs-zap-search/validators/presence-unless-value"], function (_exports, _validators, _presenceUnlessValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.boroughPresidentDispositionValidations = _exports.boroughBoardDispositionValidations = _exports.communityBoardDispositionValidations = _exports.dispositionValidations = _exports.bbDispositionForAllActionsValidations = _exports.cbDispositionForAllActionsValidations = _exports.bpDispositionForAllActionsValidations = _exports.dispositionForAllActionsValidations = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var dateMessage = 'Please enter a valid date';
  var numberMessage = 'Please enter a valid non-negative number';
  var recommendationPresenceMessage = 'Please select a recommendation';
  var dcpVotelocationPresenceMessage = 'Please enter a vote location';
  var maxLengthMessage = 'Total may not exceed 99'; // The following exports are Validation Maps to support ember-changesets
  // used in forms like the recommendation form.
  // See how they are imported and used in /app/templates/my-project/project/recommendations/add.js
  // The ability to use Validation Maps like is thanks to the addon
  // `ember-changeset-validations`. Read more about it here:
  // https://github.com/poteto/ember-changeset-validations

  /* Validations for Dispositions for All Actions */

  var dispositionForAllActionsValidations = {
    recommendation: (0, _validators.validatePresence)({
      presence: true,
      message: recommendationPresenceMessage
    })
  };
  _exports.dispositionForAllActionsValidations = dispositionForAllActionsValidations;

  var bpDispositionForAllActionsValidations = _objectSpread({}, dispositionForAllActionsValidations);

  _exports.bpDispositionForAllActionsValidations = bpDispositionForAllActionsValidations;

  var cbDispositionForAllActionsValidations = _objectSpread(_objectSpread({}, dispositionForAllActionsValidations), {}, {
    dcpVotinginfavorrecommendation: [(0, _validators.validateNumber)({
      lte: 99,
      allowBlank: true,
      message: maxLengthMessage
    }), (0, _presenceUnlessValue.default)({
      presence: true,
      unless: 'recommendation',
      value: 717170008,
      message: numberMessage
    }), (0, _validators.validateNumber)({
      positive: true,
      allowBlank: true,
      message: numberMessage
    })],
    dcpVotingagainstrecommendation: [(0, _validators.validateNumber)({
      lte: 99,
      allowBlank: true,
      message: maxLengthMessage
    }), (0, _presenceUnlessValue.default)({
      presence: true,
      unless: 'recommendation',
      value: 717170008,
      message: numberMessage
    }), (0, _validators.validateNumber)({
      positive: true,
      allowBlank: true,
      message: numberMessage
    })],
    dcpVotingabstainingonrecommendation: [(0, _validators.validateNumber)({
      lte: 99,
      allowBlank: true,
      message: maxLengthMessage
    }), (0, _presenceUnlessValue.default)({
      presence: true,
      unless: 'recommendation',
      value: 717170008,
      message: numberMessage
    }), (0, _validators.validateNumber)({
      positive: true,
      allowBlank: true,
      message: numberMessage
    })],
    dcpTotalmembersappointedtotheboard: [(0, _validators.validateNumber)({
      lte: 99,
      allowBlank: true,
      message: maxLengthMessage
    }), (0, _presenceUnlessValue.default)({
      presence: true,
      unless: 'recommendation',
      value: 717170008,
      message: numberMessage
    }), (0, _validators.validateNumber)({
      positive: true,
      allowBlank: true,
      message: numberMessage
    })],
    dcpVotelocation: (0, _validators.validatePresence)({
      presence: true,
      message: dcpVotelocationPresenceMessage
    }),
    dcpDateofvote: (0, _validators.validatePresence)({
      presence: true,
      message: dateMessage
    })
  });

  _exports.cbDispositionForAllActionsValidations = cbDispositionForAllActionsValidations;

  var bbDispositionForAllActionsValidations = _objectSpread(_objectSpread({}, dispositionForAllActionsValidations), {}, {
    dcpVotinginfavorrecommendation: [(0, _validators.validateNumber)({
      lte: 99,
      allowBlank: true,
      message: maxLengthMessage
    }), (0, _presenceUnlessValue.default)({
      presence: true,
      unless: 'recommendation',
      value: 717170002,
      message: numberMessage
    }), (0, _validators.validateNumber)({
      positive: true,
      allowBlank: true,
      message: numberMessage
    })],
    dcpVotingagainstrecommendation: [(0, _validators.validateNumber)({
      lte: 99,
      allowBlank: true,
      message: maxLengthMessage
    }), (0, _presenceUnlessValue.default)({
      presence: true,
      unless: 'recommendation',
      value: 717170002,
      message: numberMessage
    }), (0, _validators.validateNumber)({
      positive: true,
      allowBlank: true,
      message: numberMessage
    })],
    dcpVotingabstainingonrecommendation: [(0, _validators.validateNumber)({
      lte: 99,
      allowBlank: true,
      message: maxLengthMessage
    }), (0, _presenceUnlessValue.default)({
      presence: true,
      unless: 'recommendation',
      value: 717170002,
      message: numberMessage
    }), (0, _validators.validateNumber)({
      positive: true,
      allowBlank: true,
      message: numberMessage
    })],
    dcpTotalmembersappointedtotheboard: [(0, _validators.validateNumber)({
      lte: 99,
      allowBlank: true,
      message: maxLengthMessage
    }), (0, _presenceUnlessValue.default)({
      presence: true,
      unless: 'recommendation',
      value: 717170002,
      message: numberMessage
    }), (0, _validators.validateNumber)({
      positive: true,
      allowBlank: true,
      message: numberMessage
    })],
    dcpVotelocation: (0, _validators.validatePresence)({
      presence: true,
      message: dcpVotelocationPresenceMessage
    }),
    dcpDateofvote: (0, _validators.validatePresence)({
      presence: true,
      message: dateMessage
    })
  });
  /* Validations for Dispositions by Action */


  _exports.bbDispositionForAllActionsValidations = bbDispositionForAllActionsValidations;
  var dispositionValidations = {};
  _exports.dispositionValidations = dispositionValidations;

  var communityBoardDispositionValidations = _objectSpread(_objectSpread({}, dispositionValidations), {}, {
    dcpVotinginfavorrecommendation: [(0, _validators.validateNumber)({
      lte: 99,
      allowBlank: true,
      message: maxLengthMessage
    }), (0, _presenceUnlessValue.default)({
      presence: true,
      unless: 'dcpCommunityboardrecommendation',
      value: 717170008,
      message: numberMessage
    }), (0, _validators.validateNumber)({
      positive: true,
      allowBlank: true,
      message: numberMessage
    })],
    dcpVotingagainstrecommendation: [(0, _validators.validateNumber)({
      lte: 99,
      allowBlank: true,
      message: maxLengthMessage
    }), (0, _presenceUnlessValue.default)({
      presence: true,
      unless: 'dcpCommunityboardrecommendation',
      value: 717170008,
      message: numberMessage
    }), (0, _validators.validateNumber)({
      positive: true,
      allowBlank: true,
      message: numberMessage
    })],
    dcpVotingabstainingonrecommendation: [(0, _validators.validateNumber)({
      lte: 99,
      allowBlank: true,
      message: maxLengthMessage
    }), (0, _presenceUnlessValue.default)({
      presence: true,
      unless: 'dcpCommunityboardrecommendation',
      value: 717170008,
      message: numberMessage
    }), (0, _validators.validateNumber)({
      positive: true,
      allowBlank: true,
      message: numberMessage
    })],
    dcpTotalmembersappointedtotheboard: [(0, _validators.validateNumber)({
      lte: 99,
      allowBlank: true,
      message: maxLengthMessage
    }), (0, _presenceUnlessValue.default)({
      presence: true,
      unless: 'dcpCommunityboardrecommendation',
      value: 717170008,
      message: numberMessage
    }), (0, _validators.validateNumber)({
      positive: true,
      allowBlank: true,
      message: numberMessage
    })],
    dcpBoroughpresidentrecommendation: (0, _validators.validatePresence)({
      presence: false,
      message: recommendationPresenceMessage
    }),
    dcpBoroughboardrecommendation: (0, _validators.validatePresence)({
      presence: false,
      message: recommendationPresenceMessage
    }),
    dcpCommunityboardrecommendation: (0, _validators.validatePresence)({
      presence: true,
      message: recommendationPresenceMessage
    })
  });

  _exports.communityBoardDispositionValidations = communityBoardDispositionValidations;

  var boroughBoardDispositionValidations = _objectSpread(_objectSpread({}, dispositionValidations), {}, {
    dcpVotinginfavorrecommendation: [(0, _validators.validateNumber)({
      lte: 99,
      allowBlank: true,
      message: maxLengthMessage
    }), (0, _presenceUnlessValue.default)({
      presence: true,
      unless: 'dcpBoroughboardrecommendation',
      value: 717170002,
      message: numberMessage
    }), (0, _validators.validateNumber)({
      positive: true,
      allowBlank: true,
      message: numberMessage
    })],
    dcpVotingagainstrecommendation: [(0, _validators.validateNumber)({
      lte: 99,
      allowBlank: true,
      message: maxLengthMessage
    }), (0, _presenceUnlessValue.default)({
      presence: true,
      unless: 'dcpBoroughboardrecommendation',
      value: 717170002,
      message: numberMessage
    }), (0, _validators.validateNumber)({
      positive: true,
      allowBlank: true,
      message: numberMessage
    })],
    dcpVotingabstainingonrecommendation: [(0, _validators.validateNumber)({
      lte: 99,
      allowBlank: true,
      message: maxLengthMessage
    }), (0, _presenceUnlessValue.default)({
      presence: true,
      unless: 'dcpBoroughboardrecommendation',
      value: 717170002,
      message: numberMessage
    }), (0, _validators.validateNumber)({
      positive: true,
      allowBlank: true,
      message: numberMessage
    })],
    dcpTotalmembersappointedtotheboard: [(0, _validators.validateNumber)({
      lte: 99,
      allowBlank: true,
      message: maxLengthMessage
    }), (0, _presenceUnlessValue.default)({
      presence: true,
      unless: 'dcpBoroughboardrecommendation',
      value: 717170002,
      message: numberMessage
    }), (0, _validators.validateNumber)({
      positive: true,
      allowBlank: true,
      message: numberMessage
    })],
    dcpBoroughpresidentrecommendation: (0, _validators.validatePresence)({
      presence: false,
      message: recommendationPresenceMessage
    }),
    dcpBoroughboardrecommendation: (0, _validators.validatePresence)({
      presence: true,
      message: recommendationPresenceMessage
    }),
    dcpCommunityboardrecommendation: (0, _validators.validatePresence)({
      presence: false,
      message: recommendationPresenceMessage
    })
  });

  _exports.boroughBoardDispositionValidations = boroughBoardDispositionValidations;

  var boroughPresidentDispositionValidations = _objectSpread(_objectSpread({}, dispositionValidations), {}, {
    dcpBoroughpresidentrecommendation: (0, _validators.validatePresence)({
      presence: true,
      message: recommendationPresenceMessage
    }),
    dcpBoroughboardrecommendation: (0, _validators.validatePresence)({
      presence: false,
      message: recommendationPresenceMessage
    }),
    dcpCommunityboardrecommendation: (0, _validators.validatePresence)({
      presence: false,
      message: recommendationPresenceMessage
    })
  });

  _exports.boroughPresidentDispositionValidations = boroughPresidentDispositionValidations;
});