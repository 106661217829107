define("labs-zap-search/templates/my-projects/archive-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YDfvUeUR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h4\",true],[8],[0,\"\\n  Archive:\\n  \"],[7,\"span\",true],[10,\"class\",\"text-weight-normal\"],[8],[0,\"Projects that you have reviewed which are no longer in the public review process\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"text-center large-margin-top large-padding-top\"],[10,\"style\",\"padding:3rem 0;\"],[8],[0,\"\\n  \"],[1,[28,\"fa-icon\",null,[[\"icon\",\"spin\",\"size\",\"class\"],[\"spinner\",\"true\",\"5x\",\"light-gray\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/my-projects/archive-loading.hbs"
    }
  });

  _exports.default = _default;
});