define("labs-zap-search/components/initialize-foundation", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender: function didRender() {
      (0, _jquery.default)(this.element).foundation();
    }
  });

  _exports.default = _default;
});