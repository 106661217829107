define("labs-zap-search/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OMDy87LE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"cell\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"grid-x grid-padding-x grid-padding-y align-middle\"],[10,\"style\",\"min-height: 50vh\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"cell large-6 large-offset-3 text-center\"],[8],[0,\"\\n        \"],[7,\"h2\",true],[8],[0,\"Page Not Found\"],[9],[0,\"\\n\\n        \"],[7,\"p\",true],[8],[0,\"Try checking the URL for errors or \"],[4,\"link-to\",null,[[\"route\"],[\"show-geography\"]],{\"statements\":[[0,\"return to the homepage\"]],\"parameters\":[]},null],[0,\".\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/not-found.hbs"
    }
  });

  _exports.default = _default;
});