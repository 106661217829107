define("labs-zap-search/templates/components/site-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ej1BaVRh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[28,\"and\",[[25,1],[24,[\"open\"]]],null]],null,{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"site-message \",[28,\"if\",[[24,[\"warning\"]],\"warning\"],null]]]],[8],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"warning\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"grid-x grid-margin-x\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"cell text-center medium-text-left medium-shrink\"],[8],[0,\"\\n          \"],[1,[28,\"fa-icon\",null,[[\"icon\",\"size\",\"class\"],[\"exclamation-triangle\",\"2x\",\"yellow-dark\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"cell auto\"],[8],[0,\"\\n          \"],[14,1],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"dismissible\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\",true],[10,\"class\",\"close-button\"],[10,\"aria-label\",\"Dismiss message\"],[10,\"data-close\",\"\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"handleSiteMessageToggle\"]]],null]],[10,\"type\",\"button\"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[0,\"×\"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/site-message.hbs"
    }
  });

  _exports.default = _default;
});