define("labs-zap-search/templates/components/filter-mutators", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pIFeUTZz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"section\"],[[28,\"component\",[\"filter-section\"],[[\"mutateArray\",\"appliedFilters\"],[[28,\"action\",[[23,0,[]],[24,[\"mutateArray\"]]],null],[24,[\"appliedFilters\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/filter-mutators.hbs"
    }
  });

  _exports.default = _default;
});