define("labs-zap-search/models/milestone/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.STATUSCODE_OPTIONSET = _exports.REFERRAL_MILESTONEID_BY_ACRONYM_LOOKUP = _exports.MILESTONE_LIST_BY_TAB_LOOKUP = _exports.ARCHIVE_MILESTONES = _exports.REVIEWED_MILESTONES = _exports.TO_REVIEW_MILESTONES = _exports.UPCOMING_MILESTONES = _exports.REVIEW_MILESTONE_IDS = _exports.ENVIRONMENTAL_ASSESSMENT_STATEMENT_FILED = _exports.REVIEW_FILED_LAND_USE_APPLICATION = _exports.FINAL_LETTER_SENT = _exports.MAYORAL_VOTE = _exports.CPC_REVIEW_OF_COUNCIL_MODIFICATION = _exports.CITY_COUNCIL_REVIEW = _exports.CPC_PUBLIC_MEETING_VOTE = _exports.REVIEW_SESSION_POST_HEARING_FOLLOW_UP_FUTURE_VOTES = _exports.REVIEW_SESSION_PRE_HEARING_REVIEW_POST_REFERRAL = _exports.CPC_PUBLIC_MEETING_PUBLIC_HEARING = _exports.BOROUGH_BOARD_REFERRAL = _exports.BOROUGH_PRESIDENT_REFERRAL = _exports.COMMUNITY_BOARD_REFERRAL = _exports.REVIEW_SESSION_CERTIFIED_REFERRED = _exports.NOC_OF_DRAFT_EIS_ISSUED = _exports.FEIS_SUBMITTED_AND_REVIEW = _exports.DEIS_SCOPE_OF_WORK_RELEASED = _exports.DEIS_PUBLIC_HEARING_HELD = _exports.EIS_PUBLIC_SCOPING_MEETING = _exports.FINAL_SCOPE_OF_WORK_ISSUED = _exports.SCOPING_MEETING = _exports.PREPARE_FILED_EAS = _exports.FILED_EAS_REVIEW = _exports.EIS_DRAFT_SCOPE_REVIEW = _exports.CEQR_FEE_PAYMENT = _exports.PREPARE_FILED_LAND_USE_APPLICATION = _exports.LAND_USE_FEE_PAYMENT = void 0;
  var LAND_USE_FEE_PAYMENT = '6a3beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.LAND_USE_FEE_PAYMENT = LAND_USE_FEE_PAYMENT;
  var PREPARE_FILED_LAND_USE_APPLICATION = '663beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.PREPARE_FILED_LAND_USE_APPLICATION = PREPARE_FILED_LAND_USE_APPLICATION;
  var CEQR_FEE_PAYMENT = '763beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.CEQR_FEE_PAYMENT = CEQR_FEE_PAYMENT;
  var EIS_DRAFT_SCOPE_REVIEW = '7c3beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.EIS_DRAFT_SCOPE_REVIEW = EIS_DRAFT_SCOPE_REVIEW;
  var FILED_EAS_REVIEW = '783beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.FILED_EAS_REVIEW = FILED_EAS_REVIEW;
  var PREPARE_FILED_EAS = '723beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.PREPARE_FILED_EAS = PREPARE_FILED_EAS;
  var SCOPING_MEETING = '4a3beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.SCOPING_MEETING = SCOPING_MEETING;
  var FINAL_SCOPE_OF_WORK_ISSUED = '823beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.FINAL_SCOPE_OF_WORK_ISSUED = FINAL_SCOPE_OF_WORK_ISSUED;
  var EIS_PUBLIC_SCOPING_MEETING = '7e3beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.EIS_PUBLIC_SCOPING_MEETING = EIS_PUBLIC_SCOPING_MEETING;
  var DEIS_PUBLIC_HEARING_HELD = '863beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.DEIS_PUBLIC_HEARING_HELD = DEIS_PUBLIC_HEARING_HELD;
  var DEIS_SCOPE_OF_WORK_RELEASED = '483beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.DEIS_SCOPE_OF_WORK_RELEASED = DEIS_SCOPE_OF_WORK_RELEASED;
  var FEIS_SUBMITTED_AND_REVIEW = '883beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.FEIS_SUBMITTED_AND_REVIEW = FEIS_SUBMITTED_AND_REVIEW;
  var NOC_OF_DRAFT_EIS_ISSUED = '843beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.NOC_OF_DRAFT_EIS_ISSUED = NOC_OF_DRAFT_EIS_ISSUED;
  var REVIEW_SESSION_CERTIFIED_REFERRED = '8e3beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.REVIEW_SESSION_CERTIFIED_REFERRED = REVIEW_SESSION_CERTIFIED_REFERRED;
  var COMMUNITY_BOARD_REFERRAL = '923beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.COMMUNITY_BOARD_REFERRAL = COMMUNITY_BOARD_REFERRAL;
  var BOROUGH_PRESIDENT_REFERRAL = '943beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.BOROUGH_PRESIDENT_REFERRAL = BOROUGH_PRESIDENT_REFERRAL;
  var BOROUGH_BOARD_REFERRAL = '963beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.BOROUGH_BOARD_REFERRAL = BOROUGH_BOARD_REFERRAL;
  var CPC_PUBLIC_MEETING_PUBLIC_HEARING = '9e3beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.CPC_PUBLIC_MEETING_PUBLIC_HEARING = CPC_PUBLIC_MEETING_PUBLIC_HEARING;
  var REVIEW_SESSION_PRE_HEARING_REVIEW_POST_REFERRAL = '9c3beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.REVIEW_SESSION_PRE_HEARING_REVIEW_POST_REFERRAL = REVIEW_SESSION_PRE_HEARING_REVIEW_POST_REFERRAL;
  var REVIEW_SESSION_POST_HEARING_FOLLOW_UP_FUTURE_VOTES = 'a23beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.REVIEW_SESSION_POST_HEARING_FOLLOW_UP_FUTURE_VOTES = REVIEW_SESSION_POST_HEARING_FOLLOW_UP_FUTURE_VOTES;
  var CPC_PUBLIC_MEETING_VOTE = 'a43beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.CPC_PUBLIC_MEETING_VOTE = CPC_PUBLIC_MEETING_VOTE;
  var CITY_COUNCIL_REVIEW = 'a63beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.CITY_COUNCIL_REVIEW = CITY_COUNCIL_REVIEW;
  var CPC_REVIEW_OF_COUNCIL_MODIFICATION = '780593bb-ecc2-e811-8156-1458d04d0698';
  _exports.CPC_REVIEW_OF_COUNCIL_MODIFICATION = CPC_REVIEW_OF_COUNCIL_MODIFICATION;
  var MAYORAL_VOTE = 'a83beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.MAYORAL_VOTE = MAYORAL_VOTE;
  var FINAL_LETTER_SENT = 'aa3beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.FINAL_LETTER_SENT = FINAL_LETTER_SENT;
  var REVIEW_FILED_LAND_USE_APPLICATION = '6c3beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.REVIEW_FILED_LAND_USE_APPLICATION = REVIEW_FILED_LAND_USE_APPLICATION;
  var ENVIRONMENTAL_ASSESSMENT_STATEMENT_FILED = '723beec4-dad0-e711-8116-1458d04e2fb8';
  _exports.ENVIRONMENTAL_ASSESSMENT_STATEMENT_FILED = ENVIRONMENTAL_ASSESSMENT_STATEMENT_FILED;
  var REVIEW_MILESTONE_IDS = [REVIEW_SESSION_CERTIFIED_REFERRED, COMMUNITY_BOARD_REFERRAL, BOROUGH_PRESIDENT_REFERRAL, BOROUGH_BOARD_REFERRAL];
  _exports.REVIEW_MILESTONE_IDS = REVIEW_MILESTONE_IDS;
  var UPCOMING_MILESTONES = [LAND_USE_FEE_PAYMENT, PREPARE_FILED_LAND_USE_APPLICATION, REVIEW_FILED_LAND_USE_APPLICATION, CEQR_FEE_PAYMENT, EIS_DRAFT_SCOPE_REVIEW, FILED_EAS_REVIEW, FINAL_SCOPE_OF_WORK_ISSUED, EIS_PUBLIC_SCOPING_MEETING, DEIS_PUBLIC_HEARING_HELD, FEIS_SUBMITTED_AND_REVIEW, NOC_OF_DRAFT_EIS_ISSUED, REVIEW_SESSION_CERTIFIED_REFERRED, COMMUNITY_BOARD_REFERRAL, BOROUGH_PRESIDENT_REFERRAL, BOROUGH_BOARD_REFERRAL, ENVIRONMENTAL_ASSESSMENT_STATEMENT_FILED];
  _exports.UPCOMING_MILESTONES = UPCOMING_MILESTONES;
  var TO_REVIEW_MILESTONES = [COMMUNITY_BOARD_REFERRAL, BOROUGH_PRESIDENT_REFERRAL, BOROUGH_BOARD_REFERRAL];
  _exports.TO_REVIEW_MILESTONES = TO_REVIEW_MILESTONES;
  var REVIEWED_MILESTONES = [COMMUNITY_BOARD_REFERRAL, BOROUGH_PRESIDENT_REFERRAL, BOROUGH_BOARD_REFERRAL, CPC_PUBLIC_MEETING_PUBLIC_HEARING, CPC_PUBLIC_MEETING_VOTE, CITY_COUNCIL_REVIEW, MAYORAL_VOTE, FINAL_LETTER_SENT];
  _exports.REVIEWED_MILESTONES = REVIEWED_MILESTONES;
  var ARCHIVE_MILESTONES = [COMMUNITY_BOARD_REFERRAL, BOROUGH_PRESIDENT_REFERRAL, BOROUGH_BOARD_REFERRAL, CPC_PUBLIC_MEETING_VOTE, CITY_COUNCIL_REVIEW, MAYORAL_VOTE, FINAL_LETTER_SENT];
  _exports.ARCHIVE_MILESTONES = ARCHIVE_MILESTONES;
  var MILESTONE_LIST_BY_TAB_LOOKUP = {
    upcoming: UPCOMING_MILESTONES,
    'to-review': TO_REVIEW_MILESTONES,
    reviewed: REVIEWED_MILESTONES,
    archive: ARCHIVE_MILESTONES
  };
  _exports.MILESTONE_LIST_BY_TAB_LOOKUP = MILESTONE_LIST_BY_TAB_LOOKUP;
  var REFERRAL_MILESTONEID_BY_ACRONYM_LOOKUP = {
    CB: COMMUNITY_BOARD_REFERRAL,
    BP: BOROUGH_PRESIDENT_REFERRAL,
    BB: BOROUGH_BOARD_REFERRAL
  };
  _exports.REFERRAL_MILESTONEID_BY_ACRONYM_LOOKUP = REFERRAL_MILESTONEID_BY_ACRONYM_LOOKUP;
  var STATUSCODE_OPTIONSET = {
    COMPLETED: {
      code: 2,
      label: 'Completed'
    },
    IN_PROGRESS: {
      code: 717170000,
      label: 'In Progress'
    },
    NOT_STARTED: {
      label: 'Not Started'
    }
  };
  _exports.STATUSCODE_OPTIONSET = STATUSCODE_OPTIONSET;
});