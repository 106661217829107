define("labs-zap-search/templates/components/filter-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HQDQjziH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[28,\"fa-icon\",[[28,\"if\",[[28,\"contains\",[[24,[\"value\"]],[24,[\"currentValues\"]]],null],\"check-square\",\"square\"],null]],[[\"prefix\",\"class\"],[[28,\"unless\",[[28,\"contains\",[[24,[\"value\"]],[24,[\"currentValues\"]]],null],\"far\"],null],[28,\"if\",[[28,\"contains\",[[24,[\"value\"]],[24,[\"currentValues\"]]],null],\"dark-gray\",\"light-gray\"],null]]]],false],[0,\"\\n\\n\"],[1,[22,\"value\"],false],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/filter-checkbox.hbs"
    }
  });

  _exports.default = _default;
});