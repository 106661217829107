define("labs-zap-search/templates/components/project-list-map-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/W/j8rsk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[10,\"class\",\"fa-layers fa-fw\"],[10,\"style\",\"font-size: 1.5rem;\"],[8],[0,\"\\n  \"],[1,[28,\"fa-icon\",null,[[\"icon\",\"fixedWidth\"],[\"map-marker-alt\",true]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/project-list-map-pin.hbs"
    }
  });

  _exports.default = _default;
});