define("labs-zap-search/models/disposition/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.STATECODES = _exports.STATUSCODES = _exports.DISPOSITION_VISIBILITY = _exports.DCPISPUBLICHEARINGREQUIRED_OPTIONSET = void 0;
  var DCPISPUBLICHEARINGREQUIRED_OPTIONSET = {
    // eslint-disable-line
    YES: 717170000,
    NO: 717170001
  };
  _exports.DCPISPUBLICHEARINGREQUIRED_OPTIONSET = DCPISPUBLICHEARINGREQUIRED_OPTIONSET;
  var DISPOSITION_VISIBILITY = {
    GENERAL_PUBLIC: 717170003,
    LUP: 717170004
  };
  _exports.DISPOSITION_VISIBILITY = DISPOSITION_VISIBILITY;
  var STATUSCODES = {
    DRAFT: {
      label: 'Draft',
      value: 1
    },
    SAVED: {
      label: 'Saved',
      value: 717170000
    },
    SUBMITTED: {
      label: 'Submitted',
      value: 2
    },
    DEACTIVATED: {
      label: 'Deactivated',
      value: 717170001
    },
    NOT_SUBMITTED: {
      label: 'Not Submitted',
      value: 717170002
    }
  };
  _exports.STATUSCODES = STATUSCODES;
  var STATECODES = {
    ACTIVE: {
      label: 'Active',
      value: 0
    },
    INACTIVE: {
      label: 'Inactive',
      value: 1
    }
  };
  _exports.STATECODES = STATECODES;
});