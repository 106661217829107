define("labs-zap-search/templates/components/confirmation-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7DIy2mma",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"open\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"reveal-overlay\"],[10,\"style\",\"display:block;\"],[8],[0,\"\\n    \"],[7,\"div\",false],[12,\"class\",\"reveal-overlay-target\"],[3,\"action\",[[23,0,[]],[24,[\"closeModal\"]]]],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"reveal\"],[10,\"role\",\"dialog\"],[10,\"aria-hidden\",\"false\"],[10,\"tabindex\",\"-1\"],[10,\"style\",\"display:block;\"],[8],[0,\"\\n      \"],[14,1],[0,\"\\n      \"],[7,\"button\",false],[12,\"class\",\"close-button\"],[12,\"aria-label\",\"Close modal\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[24,[\"closeModal\"]]]],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"aria-hidden\",\"true\"],[8],[0,\"×\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/confirmation-modal.hbs"
    }
  });

  _exports.default = _default;
});