define("labs-zap-search/templates/components/show-project/public-documents/disposition-documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/RT0EpJy",
    "block": "{\"symbols\":[\"document\",\"@disposition\"],\"statements\":[[4,\"if\",[[23,0,[\"hasDispositionDocuments\"]]],null,{\"statements\":[[0,\"  \"],[7,\"h5\",true],[10,\"class\",\"clickable-header\"],[11,\"onClick\",[23,0,[\"toggleShowDispositionDocuments\"]]],[8],[0,\"\\n    \"],[5,\"fa-icon\",[],[[\"@icon\"],[[28,\"if\",[[23,0,[\"showDispositionDocuments\"]],\"caret-down\",\"caret-right\"],null]]]],[0,\"\\n    \"],[1,[23,0,[\"formattedDispositionName\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"showDispositionDocuments\"]]],null,{\"statements\":[[0,\"    \"],[7,\"ul\",true],[10,\"class\",\"no-bullet public-documents-list-item\"],[8],[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[\"serverRelativeUrl\",[23,2,[\"documents\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"li\",true],[8],[0,\"\\n          \"],[7,\"a\",true],[11,\"href\",[28,\"concat\",[[23,0,[\"host\"]],\"/document/disposition\",[23,1,[\"serverRelativeUrl\"]]],null]],[10,\"target\",\"_blank\"],[10,\"rel\",\"noopener noreferrer\"],[8],[0,\"\\n            \"],[1,[23,1,[\"name\"]],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/show-project/public-documents/disposition-documents.hbs"
    }
  });

  _exports.default = _default;
});