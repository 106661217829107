define("labs-zap-search/templates/components/address-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tsho96OQ",
    "block": "{\"symbols\":[\"search\",\"source\"],\"statements\":[[4,\"labs-search\",null,[[\"searchPlaceholder\",\"onSelect\",\"onClear\"],[\"Zoom to Address...\",[28,\"action\",[[23,0,[]],\"selectSearchResult\"],null],[28,\"action\",[[23,0,[]],\"clearSearchResult\"],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"small\",true],[10,\"class\",\"dark-gray\"],[8],[1,[23,1,[\"result\",\"label\"]],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[\"result\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"geocodedGeometry\"]]],null,{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[24,[\"map\",\"source\"]],\"expected `map.source` to be a contextual component but found a string. Did you mean `(component map.source)`? ('labs-zap-search/templates/components/address-search.hbs' @ L10:C9) \"],null]],[[\"options\"],[[28,\"hash\",null,[[\"type\",\"data\"],[\"geojson\",[24,[\"geocodedGeometry\"]]]]]]],{\"statements\":[[0,\"        \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,2,[\"layer\"]],\"expected `source.layer` to be a contextual component but found a string. Did you mean `(component source.layer)`? ('labs-zap-search/templates/components/address-search.hbs' @ L11:C10) \"],null]],[[\"layer\"],[[24,[\"geocodedLayer\"]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n      \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[24,[\"map\",\"call\"]],\"expected `map.call` to be a contextual component but found a string. Did you mean `(component map.call)`? ('labs-zap-search/templates/components/address-search.hbs' @ L14:C8) \"],null],\"flyTo\",[28,\"hash\",null,[[\"center\",\"zoom\"],[[24,[\"geocodedGeometry\",\"coordinates\"]],16]]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/address-search.hbs"
    }
  });

  _exports.default = _default;
});