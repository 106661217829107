define("labs-zap-search/helpers/build-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildUrl = buildUrl;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function bblDemux(bbl) {
    var boro = bbl.substring(0, 1);
    var block = parseInt(bbl.substring(1, 6), 10);
    var lot = parseInt(bbl.substring(6), 10);
    return {
      boro: boro,
      block: block,
      lot: lot
    };
  } // TODO:  Find more elegant way than hardcoding these URLs,
  // and a better way to link the user to the Zoning Resolution Online portal (rather than directing them to search).


  function zoningResolution(value) {
    // Appendix searches come up blank, so better to bring the user directly to these URLs
    var appendixUrls = {
      AppendixA: 'https://zr.planning.nyc.gov/appendix-index-uses',
      AppendixB: 'https://zr.planning.nyc.gov/appendix-b-index-special-purpose-districts',
      AppendixC: 'https://zr.planning.nyc.gov/appendix-c-table-1-city-environmental-quality-review-ceqr-environmental-requirements-e-designations',
      AppendixD: 'https://zr.planning.nyc.gov/appendix-d-zoning-map-amendment-d-restrictive-declarations',
      AppendixE: 'https://zr.planning.nyc.gov/appendix-e-design-requirements-plazas-residential-plazas-and-urban-plazas-developed-prior-october',
      AppendixF: 'https://zr.planning.nyc.gov/appendix-f-inclusionary-housing-designated-areas-and-mandatory-inclusionary-housing-areas',
      AppendixG: 'https://zr.planning.nyc.gov/appendix-g-radioactive-materials',
      AppendixH: 'https://zr.planning.nyc.gov/appendix-h-arterial-highways',
      AppendixI: 'https://zr.planning.nyc.gov/appendix-i-transit-zone',
      AppendixJ: 'https://zr.planning.nyc.gov/appendix-j-designated-areas-within-manufacturing-districts'
    };
    var romanNumerals = {
      1: 'i',
      2: 'ii',
      3: 'iii',
      4: 'iv',
      5: 'v',
      6: 'vi',
      7: 'vii',
      8: 'viii',
      9: 'ix',
      10: 'x',
      11: 'xi',
      12: 'xii',
      13: 'xiii',
      14: 'xiv'
    }; // Return the hardcoded URL if "value" is one of the appendices (searching "AppendixA" returns no results)

    if (appendixUrls[value]) {
      return appendixUrls[value];
    }

    var val = value.split('-');

    if (val[0].length === 2) {
      var articleNum = val[0][0];
      return "https://zr.planning.nyc.gov/article-".concat(romanNumerals[articleNum], "/chapter-").concat(val[0][1], "/").concat(value);
    }

    if (val[0].length === 3) {
      var _articleNum = val[0].substring(0, 2);

      return "https://zr.planning.nyc.gov/article-".concat(romanNumerals[_articleNum], "/chapter-").concat(val[0][2], "/").concat(value);
    }

    return '';
  }

  function zola(bbl) {
    var _bblDemux = bblDemux(bbl),
        boro = _bblDemux.boro,
        block = _bblDemux.block,
        lot = _bblDemux.lot;

    return "https://zola.planning.nyc.gov/lot/".concat(boro, "/").concat(block, "/").concat(lot);
  }

  function bisweb(bbl) {
    var _bblDemux2 = bblDemux(bbl),
        boro = _bblDemux2.boro,
        block = _bblDemux2.block,
        lot = _bblDemux2.lot;

    return "http://a810-bisweb.nyc.gov/bisweb/PropertyBrowseByBBLServlet?allborough=".concat(boro, "&allblock=").concat(block, "&alllot=").concat(lot, "&go5=+GO+&requestid=0");
  }

  function cpcReport(ulurpNumber, cpcUrl) {
    var serverRelativeFolderUrl = cpcUrl.replace('https://nyco365.sharepoint.com', ''); // Some ulurp numbers have extra letter and overall 11 characters compared to the usual 10
    // They have an extra letter at the end of the 6 numbers.
    // It represents the version (ex. the "A" in C18005AZMX)

    if (ulurpNumber.length === 11) {
      // pull 6 numbers AND next character after the last number
      var ulurpNumberWithLetter = ulurpNumber.match(/(\d+)./g)[0].toLowerCase();
      return "".concat(serverRelativeFolderUrl, "/").concat(ulurpNumberWithLetter, ".pdf");
    } // else assume ulurp has 10 characters, and no extra letter after first 6 numbers.
    // e.g. pull 100149 from C100149ZSM


    var ulurpNumberWithoutLetter = ulurpNumber.match(/\d+/g)[0];
    return "".concat(serverRelativeFolderUrl, "/").concat(ulurpNumberWithoutLetter, ".pdf");
  }

  function acris(bbl) {
    var _bblDemux3 = bblDemux(bbl),
        boro = _bblDemux3.boro,
        block = _bblDemux3.block,
        lot = _bblDemux3.lot;

    return "http://a836-acris.nyc.gov/bblsearch/bblsearch.asp?borough=".concat(boro, "&block=").concat(block, "&lot=").concat(lot);
  }

  function LowerCaseBorough(borough) {
    if (borough) {
      return borough.replace(/\s/g, '-').toLowerCase();
    }

    return '';
  }

  function CommProfiles(boro, cd) {
    var LowerBoro = LowerCaseBorough(boro);
    return "http://communityprofiles.planning.nyc.gov/".concat(LowerBoro, "/").concat(cd);
  }

  function buildUrl(_ref) {
    var _ref2 = _slicedToArray(_ref, 3),
        type = _ref2[0],
        value = _ref2[1],
        option = _ref2[2];

    if (type === 'zoningResolution') return zoningResolution(value);
    if (type === 'zola') return zola(value);
    if (type === 'bisweb') return bisweb(value);
    if (type === 'cpcReport') return cpcReport(value, option);
    if (type === 'acris') return acris(value);
    if (type === 'CommProfiles') return CommProfiles(value, option);
    throw new Error('invalid type passed to build-url helper');
  }

  var _default = Ember.Helper.helper(buildUrl);

  _exports.default = _default;
});