define("labs-zap-search/components/labs-ui/site-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'header',
    classNames: ['site-header'],
    ariaRole: 'banner',
    closed: true,
    responsiveNav: false,
    responsiveSize: 'large'
  });

  _exports.default = _default;
});