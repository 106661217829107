define("labs-zap-search/templates/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sPpVjWbL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"cell\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"grid-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"grid-x grid-padding-x grid-padding-y align-middle\"],[10,\"style\",\"min-height: 50vh\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"cell large-6 large-offset-3 text-center\"],[8],[0,\"\\n\"],[4,\"auth/do-logout\",null,null,{\"statements\":[[0,\"          \"],[7,\"h2\",true],[8],[0,\"You have successfully signed out.\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"h2\",true],[8],[0,\"Signing out. Please wait...\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/logout.hbs"
    }
  });

  _exports.default = _default;
});