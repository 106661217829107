define("labs-zap-search/templates/components/consolidated-cds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R2D4vait",
    "block": "{\"symbols\":[\"boro\",\"cd\",\"i\"],\"statements\":[[4,\"each\",[[24,[\"consolodatedCDs\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"label light-gray\"],[8],[0,\"\\n    \"],[7,\"strong\",true],[10,\"class\",\"dark-gray\"],[8],[1,[23,1,[\"boroName\"]],false],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"medium-gray\"],[8],[0,\"|\"],[9],[0,\"\\n    \"],[7,\"span\",true],[10,\"class\",\"dark-gray\"],[8],[0,\"\\n      CD\\n\"],[4,\"each\",[[23,1,[\"cds\"]]],null,{\"statements\":[[1,[28,\"if\",[[23,3,[]],\",\"],null],false],[0,\"\\n\"],[4,\"if\",[[24,[\"cdlink\"]]],null,{\"statements\":[[0,\"          \"],[7,\"a\",true],[11,\"href\",[29,[[28,\"build-url\",[\"CommProfiles\",[23,1,[\"boroName\"]],[23,2,[]]],null]]]],[10,\"target\",\"_blank\"],[8],[7,\"strong\",true],[8],[1,[23,2,[]],false],[9],[9]],\"parameters\":[]},{\"statements\":[[1,[23,2,[]],false]],\"parameters\":[]}]],\"parameters\":[2,3]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/consolidated-cds.hbs"
    }
  });

  _exports.default = _default;
});