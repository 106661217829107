define("labs-zap-search/templates/my-projects/assignment/recommendations/done", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FQe8JAay",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"h2\",true],[8],[0,\"Thank you!\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"grid-x grid-margin-x\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"large-5 cell large-order-2\"],[8],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"Recommendations submitted for:\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"project-summary-card callout large-margin-bottom\"],[8],[0,\"\\n      \"],[7,\"h3\",true],[10,\"class\",\"tiny-margin-bottom\"],[8],[0,\"\\n        \"],[5,\"link-to\",[],[[\"@route\",\"@model\"],[\"show-project\",[24,[\"model\",\"project\"]]]],{\"statements\":[[0,\"\\n          \"],[1,[24,[\"model\",\"project\",\"dcpProjectname\"]],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n        \"],[7,\"small\",true],[10,\"class\",\"dark-gray\"],[8],[1,[24,[\"model\",\"project\",\"dcpUlurpNonulurp\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"h5\",true],[10,\"class\",\"applicant\"],[8],[1,[24,[\"model\",\"project\",\"applicants\"]],false],[9],[0,\"\\n      \"],[7,\"p\",true],[8],[1,[24,[\"model\",\"project\",\"dcpProjectbrief\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"large-7 cell\"],[8],[0,\"\\n\\n    \"],[7,\"h3\",true],[8],[0,\"Your recommendations have been submitted.\"],[9],[0,\"\\n \\n    \"],[5,\"link-to\",[[12,\"class\",\"button gray\"]],[[\"@route\"],[\"my-projects.to-review\"]],{\"statements\":[[0,\"\\n      Go to My Projects\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/my-projects/assignment/recommendations/done.hbs"
    }
  });

  _exports.default = _default;
});