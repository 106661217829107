define("labs-zap-search/components/labs-bbl-lookup", ["exports", "labs-ember-search/components/labs-bbl-lookup"], function (_exports, _labsBblLookup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _labsBblLookup.default;
    }
  });
});