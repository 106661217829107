define("labs-zap-search/templates/components/project-bbl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aYveCTTQ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"tool-tipster\",null,[[\"content\",\"interactive\",\"contentAsHTML\",\"triggerEvent\",\"tagName\",\"classNames\"],[[24,[\"tooltip\"]],true,true,\"click\",\"span\",\"label\"]],{\"statements\":[[0,\"  \"],[1,[22,\"bbl\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/project-bbl.hbs"
    }
  });

  _exports.default = _default;
});