define("labs-zap-search/app", ["exports", "ember-data", "ember-load-initializers", "labs-zap-search/resolver", "labs-zap-search/config/environment"], function (_exports, _emberData, _emberLoadInitializers, _resolver, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var ALLOWED_MISSING_MODEL_KEYS = ['nodeType', 'size', 'length', 'setUnknownProperty', 'didCommit', 'then', 'willMergeMixin', 'concatenatedProperties', 'mergedProperties', 'isQueryParams'];
  Model.reopen({
    unknownProperty: function unknownProperty(key) {
      if (ALLOWED_MISSING_MODEL_KEYS.includes(key)) return;
      throw new Error("Unexpected access of ".concat(key, " on ").concat(this));
    }
  });
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});