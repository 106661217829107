define("labs-zap-search/templates/components/load-more-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0vvTHqU9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[10,\"class\",\"text-center\"],[10,\"style\",\"margin:3rem 0 6rem;\"],[8],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"button projects-load-more-button gray\"],[11,\"onClick\",[28,\"unless\",[[24,[\"noMoreRecords\"]],[28,\"queue\",[[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"page\"]]],null],[28,\"add\",[[24,[\"page\"]],1],null]],null],[28,\"perform\",[[24,[\"task\"]]],null]],null]],null]],[11,\"disabled\",[28,\"or\",[[24,[\"noMoreRecords\"]],[24,[\"task\",\"isRunning\"]]],null]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"task\",\"isRunning\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"fa-icon\",null,[[\"icon\",\"class\",\"transform\"],[\"spinner\",\"fa-spin\",\"grow-8\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      LOAD MORE\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/load-more-button.hbs"
    }
  });

  _exports.default = _default;
});