define("labs-zap-search/templates/components/auth/do-logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1ohc8PUE",
    "block": "{\"symbols\":[\"&inverse\",\"&default\"],\"statements\":[[7,\"iframe\",true],[11,\"onload\",[28,\"action\",[[23,0,[]],[23,0,[\"didLogoutNycId\"]]],null]],[11,\"src\",[29,[[23,0,[\"nycIDHost\"]],\"/idpLogout.htm?x-frames-allow-from=\",[23,0,[\"origin\"]]]]],[10,\"style\",\"display: none;\"],[8],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"iFrameDidLoad\"]]],null,{\"statements\":[[0,\"  \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/auth/do-logout.hbs"
    }
  });

  _exports.default = _default;
});