define("labs-zap-search/templates/components/mapbox-gl-dynamic-tiles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lt6XUuLs",
    "block": "{\"symbols\":[\"source\",\"&default\"],\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[24,[\"map\",\"source\"]],\"expected `map.source` to be a contextual component but found a string. Did you mean `(component map.source)`? ('labs-zap-search/templates/components/mapbox-gl-dynamic-tiles.hbs' @ L1:C3) \"],null]],[[\"sourceId\",\"options\"],[[24,[\"sourceId\"]],[28,\"hash\",null,[[\"type\",\"tiles\"],[\"vector\",[24,[\"tiles\"]]]]]]],{\"statements\":[[0,\"  \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/mapbox-gl-dynamic-tiles.hbs"
    }
  });

  _exports.default = _default;
});