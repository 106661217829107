define("labs-zap-search/templates/my-projects/to-review", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ShbUJuIv",
    "block": "{\"symbols\":[\"assignment\"],\"statements\":[[7,\"h4\",true],[8],[0,\"\\n  To Review:\\n  \"],[7,\"span\",true],[10,\"class\",\"text-weight-normal\"],[8],[0,\"Projects subject to ULURP and/or referred out for your review\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"model\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"sortedProjects\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"to-review-project-card\",null,[[\"assignment\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"grid-x grid-padding-x grid-padding-y align-bottom\"],[10,\"style\",\"min-height: 20vh\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"cell large-10 large-offset-1 xlarge-8 xlarge-offset-2  text-center\"],[8],[0,\"\\n      \"],[7,\"h2\",true],[10,\"class\",\"charcoal\"],[8],[0,\"You currently have no projects to review.\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"grid-x grid-padding-x grid-padding-y\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"cell large-10 large-offset-1 xlarge-8 xlarge-offset-2  text-center\"],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"\"],[8],[0,\"If something has gone wrong, or you believe projects are missing from this list, please contact City Planning at \"],[7,\"a\",true],[10,\"href\",\"mailto:ZAP_feedback_DL@planning.nyc.gov\"],[8],[0,\"ZAP_feedback_DL@planning.nyc.gov\"],[9],[0,\" or 212-720-3300.\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/my-projects/to-review.hbs"
    }
  });

  _exports.default = _default;
});