define("labs-zap-search/templates/components/show-project/milestone-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SMSvnrdi",
    "block": "{\"symbols\":[\"milestone\",\"@milestones\",\"&default\",\"@listName\"],\"statements\":[[7,\"ul\",true],[10,\"class\",\"no-bullet milestones-list\"],[8],[0,\"\\n  \"],[7,\"li\",true],[10,\"class\",\"grid-x grid-padding-small milestone-header\"],[11,\"onClick\",[23,0,[\"toggleShowMilestoneList\"]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"cell shrink\"],[8],[0,\"\\n      \"],[5,\"fa-icon\",[[12,\"style\",\"margin-left: -10px; margin-top: 10px\"]],[[\"@icon\"],[[28,\"if\",[[23,0,[\"showMilestoneList\"]],\"caret-down\",\"caret-right\"],null]]]],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"cell shrink\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"milestone-icon fa-layers fa-fw\"],[8],[0,\"\\n        \"],[14,3],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"cell auto\"],[8],[0,\"\\n      \"],[7,\"h4\",true],[10,\"class\",\"no-margin\"],[8],[0,\"\\n        \"],[7,\"span\",true],[8],[0,\"\\n          \"],[1,[23,4,[]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"showMilestoneList\"]]],null,{\"statements\":[[4,\"if\",[[23,2,[]]],null,{\"statements\":[[4,\"each\",[[23,2,[]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"project-milestone\",null,[[\"milestone\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"      No Milestones\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/show-project/milestone-list.hbs"
    }
  });

  _exports.default = _default;
});