define("labs-zap-search/templates/components/location-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8Yz2V60s",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[11,\"class\",[29,[[28,\"if\",[[28,\"and\",[[24,[\"locationMissing\"]],[24,[\"checkIfMissing\"]]],null],\"is-invalid-label\"],null]]]],[8],[0,\"\\n  Hearing Location\\n    \"],[1,[28,\"input\",null,[[\"class\",\"type\",\"maxlength\",\"placeholder\",\"autocomplete\",\"value\",\"key-down\",\"key-up\"],[[28,\"if\",[[28,\"and\",[[24,[\"locationMissing\"]],[24,[\"checkIfMissing\"]]],null],\"is-invalid-input\"],null],\"text\",200,\"Add a location\",\"off\",[23,0,[\"location\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"location\"]]],null]],null],[28,\"action\",[[23,0,[]],\"setLocation\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[24,[\"locationMissing\"]],[24,[\"checkIfMissing\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"form-error is-visible\"],[10,\"role\",\"alert\"],[8],[0,\"Please enter a valid location for your hearing\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/location-input.hbs"
    }
  });

  _exports.default = _default;
});