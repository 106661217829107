define("labs-zap-search/helpers/lookup-dcp-applicability", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lookupDcpApplicability = lookupDcpApplicability;
  _exports.default = _exports.dcpApplicabilityLookup = void 0;
  var dcpApplicabilityLookup = [{
    code: 1,
    searchField: 'Racial Equity Report Required'
  }, {
    code: 2,
    searchField: 'Racial Equity Report Not Required'
  }, {
    code: null,
    searchField: 'Unsure at this time'
  }];
  _exports.dcpApplicabilityLookup = dcpApplicabilityLookup;

  function lookupDcpApplicability() {
    return dcpApplicabilityLookup;
  }

  var _default = Ember.Helper.helper(lookupDcpApplicability);

  _exports.default = _default;
});