define("labs-zap-search/templates/components/filter-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zPmPcsjR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"filter-header\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"switch tiny float-left\"],[8],[0,\"\\n    \"],[7,\"input\",true],[10,\"class\",\"switch-input\"],[11,\"checked\",[28,\"if\",[[28,\"contains\",[[24,[\"filterNames\"]],[24,[\"appliedFilters\"]]],null],\"checked\"],null]],[10,\"type\",\"checkbox\"],[8],[9],[0,\"\\n    \"],[7,\"label\",false],[12,\"class\",\"switch-paddle\"],[3,\"action\",[[23,0,[]],[24,[\"mutateWithAction\"]]]],[8],[0,\"\\n\\n      \"],[7,\"span\",true],[10,\"class\",\"show-for-sr\"],[8],[0,\"Toggle \"],[1,[22,\"filterTitle\"],false],[0,\" Filter\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"h6\",false],[12,\"class\",\"filter-name\"],[3,\"action\",[[23,0,[]],[24,[\"mutateWithAction\"]]]],[8],[0,\"\\n    \"],[1,[22,\"filterTitle\"],false],[4,\"if\",[[24,[\"tooltip\"]]],null,{\"statements\":[[7,\"sup\",true],[10,\"class\",\"dark-gray\"],[8],[1,[28,\"icon-tooltip\",null,[[\"tip\"],[[24,[\"tooltip\"]]]]],false],[9]],\"parameters\":[]},null],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"filter-controls\"],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-zap-search/templates/components/filter-wrapper.hbs"
    }
  });

  _exports.default = _default;
});